import React from 'react';

// import enums from './enums';
import { shallowCopy } from './helpers';
import { getUserInfo, updateUserProfile } from './ajax';
import validation from './validation';

import LoadingCenter from './LoadingCenter';
import { FormCheckbox } from './FormElements';


class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading', // 'loaded', 'saving', 'saved'
      form: null,
      validate: false,
      tree: null,
    };
    this.merge = this.merge.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      // load profile data
      let user = await getUserInfo();
      console.log('[Profile] Fetched user info?', user);

      this.setState({
        state: 'loaded',
        form: {
          id: user.id,
          email: user.email,
          // name: user.name,
          nameFirst: user.nameFirst,
          nameLast: user.nameLast,
          // unsubscribeFromMarketing: user.unsubscribeFromMarketing,
        },
      });
    }
    catch(e) {
      console.error('[Profile] Error in componentDidMount()', e);
    }
  }

  merge(newData) {
    // console.log('[Profile] Running merge...', { newData });
    let form = shallowCopy(this.state.form, newData);
    this.setState({
      form,
      tree: this.validate(null, this.state.form),
    });
  }

  validate(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.form;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.validate) {
        // check whether attribute inside validation tree is valid, if not return 'error' CSS class
        if (this.state.tree && this.state.tree[attribute] && this.state.tree[attribute].length > 0) {
          return 'error';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        // name: validation.validateString(data.name) ? null : 'Name is required.',
        nameFirst: validation.validateString(data.nameFirst) ? null : 'First Name is required.',
        nameLast: validation.validateString(data.nameLast) ? null : 'Last Name is required.',
      };
      return tree;
    }
  }

  async onSubmit() {
    try {
      console.log('[Profile] Saving...');
      let tree = this.validate(null, this.state.form);
      // console.log('we have a validation tree', tree);
      let isHealthy = validation.isTreeHealthy(tree);
      this.setState({
        tree,
        validate: true
      });
      if (isHealthy) {
        let data = {
          // name: this.state.form.name,
          nameFirst: this.state.form.nameFirst,
          nameLast: this.state.form.nameLast,
          // unsubscribeFromMarketing: this.state.form.unsubscribeFromMarketing,
        };
        console.log('[Profile] We have a valid form.', data);
        this.setState({ state: 'saving' });

        let saved = await updateUserProfile(data);
        this.setState({ state: 'saved' });

      } else {
        console.log('[Profile] We have an invalid form.', this.state.form);
      }
    } catch(e) {
      console.error(`[Profile] Error handling onSubmit().`, e);
    }
  }

  render() {

    return (
      <div>
        <h1>Profile</h1>

        {
          this.state.state === 'loading' &&
          <div>
            <LoadingCenter />
          </div>
        }

        {
          (this.state.state === 'loaded' || this.state.state === 'saved') &&
          <div className='pi3form'>

            <div className="formRow">
              <label htmlFor="input-message">Email</label>
              <input
                type="text"
                disabled
                id='input-email'
                name='email'
                value={this.state.form.email}
              />
            </div>

            <div className="formRow">
              <label htmlFor="input-message">First Name</label>
              <input
                type="text"
                id='input-name-first'
                name='name'
                value={this.state.form.nameFirst}
                onChange={ev => this.merge({ nameFirst: ev.target.value })}
                className={` ${this.validate('nameFirst')}`}
              />
            </div>

            <div className="formRow">
              <label htmlFor="input-message">Last Name</label>
              <input
                type="text"
                id='input-name-last'
                name='name'
                value={this.state.form.nameLast}
                onChange={ev => this.merge({ nameLast: ev.target.value })}
                className={` ${this.validate('nameLast')}`}
              />
            </div>

            {/* <div className="formRow">
              <FormCheckbox
                isChecked={this.state.form.unsubscribeFromMarketing}
                isDisabled={false}
                label='Unsubscribe from marketing emails'
                onChange={u => this.merge({ unsubscribeFromMarketing: u })}
              />
            </div> */}

            <div>
              <div className="formRow submitRow">
                <button className='pi3button' onClick={this.onSubmit}>Save</button>
              </div>

              {this.state.state === 'saved' &&
                <div>
                  Saved changes.
                </div>
              }
            </div>

          </div>
        }

        {
          this.state.state === 'saving' &&
          <div className='pi3form'>

            <div className="formRow">
              <label htmlFor="input-message">Email</label>
              <input
                type="text"
                disabled
                id='input-email'
                name='email'
                value={this.state.form.email}
              />
            </div>

            <div className="formRow">
              <label htmlFor="input-message">First Name</label>
              <input
                type="text"
                id='input-name'
                name='name'
                value={this.state.form.nameFirst}
                disabled
              />
            </div>

            <div className="formRow">
              <label htmlFor="input-message">Last Name</label>
              <input
                type="text"
                id='input-name'
                name='name'
                value={this.state.form.nameLast}
                disabled
              />
            </div>

            {/* <div className="formRow">
              <FormCheckbox
                isChecked={this.state.form.unsubscribeFromMarketing}
                isDisabled={false}
                label='Unsubscribe from marketing emails'
                disabled
              />
            </div> */}

            <div>
              <div className="formRow submitRow">
                <button className='pi3button disabled'>Saving...</button>
              </div>
            </div>

          </div>
        }

      </div>
    );
 
  }
}

export default Profile;
