
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';

import LoadingCenter from './LoadingCenter';

import { getFileTwine, getUserInfo } from './ajax';


// <CourseBlockTwineChat block={block} key={block.id} />
class CourseBlockTwineChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading', // 'loading', 'loaded'
      passages: [], // all passages in chat model
      passageCurrent: null, // deprecated?

      messages: [], // all messages already written
      answers: [], // currently available answers to user
      userTyping: null, // 'X is typing a message...'
      meName: 'Me', // name of 'me' character
    };
    this.messageEndRef = React.createRef();
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.pushPassage = this.pushPassage.bind(this);
    this.pushMessage = this.pushMessage.bind(this);
    this.onAnswerSelect = this.onAnswerSelect.bind(this);
    this.retry = this.retry.bind(this);
  }
  scrollToBottom() {
    setTimeout(() => {
      if (this.messageEndRef && this.messageEndRef.current) {
        this.messageEndRef.current.scrollTop = 100000;
      }
    }, 30);
  }
  async componentDidMount() {
    try {
      let block = this.props.block;
      let course = this.props.course;

      // load & parse twine file
      let file = await getFileTwine(block.url);
      let passages = tweeToChatflow(file);
      console.log('[CourseBlockTwineChat] Parsed twee file.', { passages });

      // load user info (for 'me' name)
      let userInfo = await getUserInfo();
      let meName = userInfo.name.split(' ')[0];
      console.log('[CourseBlockTwineChat] Extracted user name.', { meName });

      // load chat speakers
      let speakers = course.courseSpeakers;
      console.log('[CourseBlockTwineChat] Extracted course speakers.', { speakers });

      this.setState({ stage: 'loaded', meName, passages, speakers }, () => {
        // after setting passages, load first message
        this.pushPassage('initial');
      });

    }
    catch(e) {
      console.error(`[CourseBlockTwineChat] Error in componentDidMount().`, e);
    }
  }
  // push message from 'speaker'
  pushPassage(passageId) {
    let passage = this.state.passages.find(p => p.step === passageId);
    if (!passage) {
      console.error('[CourseBlockTwineChat] Could not find passage.', { passages: this.state.passages, passageId });
      return;
    }
    let messages = this.state.messages;

    // named speaker (not narrator)
    if (passage.speaker) {
      // after 1 second start 'typing'
      setTimeout(() => {
        this.setState({
          userTyping: passage.speaker,
          answers: [],
        });
        this.scrollToBottom();
      }, 1000);

      // after 2 seconds of 'typing', render message & answers
      setTimeout(() => {
        let answers = [];
        if (passage.interpret && passage.interpret.length > 0) {
          for (let i of passage.interpret) {
            answers.push({
              text: i.statements[0],
              goto: i.goto,
            });
          }
        }
        messages.push({
          id: messages.length + 1,
          speaker: passage.speaker,
          text: passage.say,
          answers: answers,
        });
        this.setState({
          userTyping: null,
          messages,
          answers,
        });
        this.scrollToBottom();
      }, 4000);
    }

    // narrator mode
    else {
      let answers = [];
      if (passage.interpret && passage.interpret.length > 0) {
        for (let i of passage.interpret) {
          answers.push({
            text: i.statements[0],
            goto: i.goto,
          });
        }
      }
      messages.push({
        id: messages.length + 1,
        speaker: 'narrator',
        text: passage.say,
        answers: answers,
      });
      this.setState({
        messages,
        answers,
      });
    }

  }
  // push message from 'responder'
  pushMessage(message) {
    let messages = this.state.messages;
    messages.push({
      id: messages.length + 1,
      speaker: null,
      text: message,
      answers: [],
    });
    // scroll to bottom
    this.scrollToBottom();

  }
  onAnswerSelect(answer) {
    this.setState({ answers: [] }); // prevent answering again
    this.pushMessage(answer.text); // push my answer to messages
    this.pushPassage(answer.goto); // push the response to my answer
  }
  async retry() {
    console.debug('Running retry()...');
    try {
      this.setState({
        stage: 'loaded',
        userTyping: null,
        messages: [],
      }, () => {
        // after setting passages, load first message
        this.pushPassage('initial');
      });
    }
    catch(e) {
      console.error(`[CourseBlockTwineChat] Error in retry().`, e);
    }
  }
  render() {

    if (this.state.stage === 'loading') {
      return (
        <div className='pi3courseTwineChat'>
        </div>
      )
    }

    let messagesRender = '';
    if (this.state.messages && this.state.messages.length > 0) {
      messagesRender = this.state.messages.map(message =>
        <CourseBlockTwineChatMessage
          message={message}
          meName={this.state.meName}
          key={message.id}
          speakers={this.state.speakers}
          retry={this.retry}
        />);
    }

    let typingRender = '';
    if (this.state.userTyping) {
      typingRender = (
        <div className='pi3courseTwineChatMessageSpeaker'>
          <div className='messageSpeakerHeader'>
            <img className='messageSpeakerImage' src={getSpeakerIconUrl(this.state.userTyping, this.state.speakers)} />
            <h3>{this.state.userTyping}</h3>
          </div>
          <div className='messageSpeakerText'>
            <div className='pi3typingIndicator'>
              <div className='pi3typingIndicatorDot'></div>
              <div className='pi3typingIndicatorDot'></div>
              <div className='pi3typingIndicatorDot'></div>
            </div>
          </div>
        </div>
      );
    }

    let answersRender = '';
    if (this.state.answers && this.state.answers.length > 0) {
      // answersRender
      let answersRenderInner = [];
      for (let i = 0; i < this.state.answers.length; i++) {
        let answer = this.state.answers[i];
        answersRenderInner.push(
          <div
            key={i}
            className='pi3courseTwineChatAnswer'
            onClick={() => this.onAnswerSelect(answer)}
          >
            {answer.text}
          </div>
        );
      }
      answersRender = (
        <div className='pi3courseTwineChatAnswers'>
          <h3>How will you respond?</h3>
          <div className='chatAnswersContainer'>
            {answersRenderInner}
          </div>
        </div>
      )
    }

    return (
      <div className='pi3courseTwineChat'>
        <div className='pi3courseTwineChatMessages' ref={this.messageEndRef}>
          {messagesRender}
          {typingRender}
          <div>&nbsp;</div>
        </div>
        {answersRender}
      </div>
    );
  }
}

export default CourseBlockTwineChat;


// <CourseBlockTwineChatMessage passage={passage} />
class CourseBlockTwineChatMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    let message = this.props.message;

    if (message.speaker && message.speaker === 'narrator') {
      return (
        <div className='pi3courseTwineChatMessageNarrator'>
          {message.answers.length === 0 && // detect final narration (chat done)
            <div>
              <img src='/static/images/speakers-narrator-cat.svg' />
              <h3>Activity Complete</h3>
            </div>
          }
          <div>{renderMessageWithLineBreaks(message.text)}</div>
          {message.answers.length === 0 && // detect final narration (chat done)
            <div style={{ margin: '20px 0 0 0' }}>
              <a className='twineChatButton' onClick={() => this.props.retry()}>Try Again</a>
            </div>
          }
        </div>
      );
    }

    else if (message.speaker) {
      return (
        <div className='pi3courseTwineChatMessageSpeakerContainer'>
          <div className='pi3courseTwineChatMessageSpeaker'>
            <div className='messageSpeakerHeader'>
              <img className='messageSpeakerImage' src={getSpeakerIconUrl(message.speaker, this.props.speakers)} />
              <h3>{message.speaker}</h3>
            </div>
            <div className='messageSpeakerText'>
              <div>{renderMessageWithLineBreaks(message.text)}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='pi3courseTwineChatMessageMeContainer'>
        <div className='pi3courseTwineChatMessageMe'>
          <div className='messageSpeakerHeader'>
            <img className='messageSpeakerImage' src={getSpeakerIconUrl(message.speaker, this.props.speakers)} />
            <h3>{this.props.meName}</h3>
          </div>
          <div className='messageMeText'>
            <div>{renderMessageWithLineBreaks(message.text)}</div>
          </div>
        </div>
      </div>
    );

  }

}



function getSpeakerIconUrl(speaker, speakers = []) {
  console.log('Getting speaker icon', { speaker, speakers })
  if (!speaker || !speakers) {
    return '/static/images/speakers-me.png';
  }
  let match = speakers.find(s => s.name.toLowerCase() === speaker.toLowerCase());
  if (match) {
    return match.iconLink;
  }
  return '/static/images/speakers-me.png';
}

function renderMessageWithLineBreaks(message) {
  return message.split('\n').map((line, index) => (
    <div key={index}>
      {line}
    </div>
  ));
}

const tweeToChatflow = (source) => {

  // split source into passages
  const passages = source
    // each passage starts with a special header (::) followed by the body text of the passage
    .split(/^::/m)
    // filter out empty passages
    .filter(s => s.trim() !== '')
    // reformat and trim passages (add back the '::' prefix that was removed during the split, trim extra spaces)
    .map(s => ':: ' + s.trim())
    .map((passageSource) => {
      // take each passage and split by newlines, first line becomes headerLine
      const [headerLine, ...lines] = passageSource.split(/\r?\n/);
      // headerBits will contain three groups of information, if present:
      //   ::\s*(.*?(?:\\\s)?) captures the passage's name (with optional escaped spaces).
      //   (\[.*?\])? captures an optional passage metadata block, enclosed in square brackets [].
      //   (\{.*?\})? captures an optional passage tag or content block, enclosed in curly braces {}
      const headerBits = /^::\s*(.*?(?:\\\s)?)\s*(\[.*?\])?\s*(\{.*?\})?\s*$/.exec(
        headerLine
      );
      const [, rawName, rawTags, rawMetadata] = headerBits;

      let speaker = null;
      if (rawTags) {
        speaker = rawTags.match(/\[speaker-(.+?)\]/);
        speaker = speaker ? speaker[1] : null;
      }

      return {
        name: rawName
          // remove leading escaped spaces
          .replace(/^(\\\s)+/g, match => ' '.repeat(match.length / 2))
          // remove trailing escaped spaces
          .replace(/(\\\s)+$/g, match => ' '.repeat(match.length / 2))
          // unescape special characters
          .replace(/\\([[\]{}])/g, '$1')
          // unescape double backlashes
          .replace(/\\\\/g, '\\') // unescapeForTweeHeader
          // replace spaces with underscores
          .replace(/[ ]/g, '_') 
          // remove non-alphanumeric characters (except underscores and hyphens)
          .replace(/[^A-Za-z0-9\_\-]+/g, '')
          // convert to lowercase
          .toLowerCase()
        ,
        text: lines
          //  ^\\: searches for a backslash-escaped colon (\\:) at the beginning of any line (^) in a multiline string (indicated by the gm)
          // replaces every escaped colon (\\:) with a regular colon (:)
          .map(v => v.replace(/^\\:/gm, ':')) // unescapeForTweeText
          .join('\n')
          .trim()
        ,
        rawTags,
        rawMetadata,
        speaker,
      };
    })
  ;

  // console.log('[tweeToChatflow] Extracted passages.', passages);

  // story metadata, such as format, initial step name, tag colors
  const storydata = JSON.parse(passages.filter(p => p.name == 'storydata')?.[0]?.text ?? '{}');

  // console.log('[tweeToChatflow] Extracted storydata.', storydata);

  // step name generator?
  const initialStepName = (name) => {
    const startName = storydata.start
      // replace spaces with underscores
      .replace(/[ ]/g, '_')
      // remove special characters (leave alfanumeric, underscores & hyphens)
      .replace(/[^A-Za-z0-9\_\-]+/g, '')
      .toLowerCase()
    ;
    const stepName = name
      // replace spaces with underscores
      .replace(/[ ]/g, '_')
      // remove special characters (leave alfanumeric, underscores & hyphens)
      .replace(/[^A-Za-z0-9\_\-]+/g, '')
      .toLowerCase()
    ;
    if (startName == stepName)
      return 'initial';
    return stepName;
  };


  const chatflow = passages
    // exclude passages with name equal to 'storydata', 'storytitle', or 'storystylesheet' (exclude metadata passages)
    .filter(p => !['storydata', 'storytitle', 'storystylesheet'].includes(p.name))
    .map((passage) => {
      // \[\[ matches the opening double square brackets [[.
      // (.*?) is a capturing group that matches any characters inside the brackets (non-greedily).
      // \]\] matches the closing double square brackets ]].
      const choices = (passage.text.match(/\[\[(.*?)\]\]/g) ?? [])
        .map(p => p.replace(/\[\[(.*?)\]\]/, (m0, m1) => m1))
      ;
      return {
        step: initialStepName(passage.name),
        // \[\[ matches the opening double square brackets [[.
        // (.*?): This captures any characters inside the brackets (non-greedily).
        // \]\] matches the closing double square brackets ]].
        say: passage.text.replace(/\[\[(.*?)\]\]/g, '').trim(),
        // \-\>: This matches the literal characters ->. The backslashes (\) are used to escape the hyphen (-), as it has special meaning in regex.
        // .*?: This matches any characters after -> (non-greedily).
        // $: This asserts the end of the string, ensuring the match occurs only at the end.
        // The matched part is replaced with an empty string '', which effectively removes the -> and anything after it.
        choices: choices.map(c => c.replace(/\-\>.*?$/, '')),
        interpret: choices.map(c => ({
          as: 'meaning',
          statements: [c.replace(/\-\>.*?$/, '')],

          // Input Choice: "Go to the forest -> forestScene".
          // Processed Destination:
          //   After removing text before ->: "forestScene".
          //   After replacing spaces: "forestScene" (no spaces to replace).
          //   After removing special characters: "forestscene" (remains unchanged).
          //   After converting to lowercase: "forestscene".
          // Function Call: initialStepName("forestscene").
          // Resulting goto: The result of initialStepName("forestscene") is assigned to goto.
          goto: initialStepName(
            c
              .replace(/^.*?\-\>/, '')
              .replace(/[ ]/g, '_')
              .replace(/[^A-Za-z0-9\_\-]+/g, '')
              .toLowerCase()
          ),
        })),
        speaker: passage.speaker,
      };
    })
  ;

  return chatflow;

};