'use strict';

const React = require('react');

// const _ = require('underscore');

var subscriptions = [];
function subscribe(callback) {
  subscriptions.push(callback);
  //console.log('[StatusBar] Component subscribed.');
}

function unsubscribe(callback) {
  // subscriptions = _.without(subscriptions, callback);
  let newSubscriptions = [];
  for (let subscription of subscriptions) {
    if (subscription !== callback)
      newSubscriptions.push(subscription);
  }
  subscriptions = newSubscriptions;
  //console.log('[StatusBar] Component unsubscribed.');
}

function sendMessage(message, status = null) {
  for (let subscription of subscriptions) {
    if (subscription) subscription(message, status);
  }
  // _.map(subscriptions, s => {
  //   if (_.isFunction(s)) {
  //     s(message);
  //   }
  // });
}

class StatusBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      messages: []
    };
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.push = this.push.bind(this);
    this.dismissLastMessage = this.dismissLastMessage.bind(this);
    this.dismissFirstMessage = this.dismissFirstMessage.bind(this);
    subscribe(this.push);
  }
  componentWillUnmount() {
    //console.log('[StatusBar] Component will unmount.');
    unsubscribe(this.push);
  }
  push(message, status = null) {
    var messages = this.state.messages;
    let newMessage = { message, status };
    messages.push(newMessage);
    this.setState({ messages });

    // we're gonna display, and hide after 5 secs
    setTimeout(() => {
      this.dismissFirstMessage();
      // this.dismissMessage(newMessage);
    }, 5000);
  }
  dismissLastMessage() {
    var messages = this.state.messages;
    messages.pop();
    this.setState({ messages });
  }
  dismissFirstMessage() {
    let messages = this.state.messages;
    messages.shift();
    this.setState({ messages });
  }
  render() {

    // maybe we can just return ''?
    if (this.state.messages.length < 1) {
      // need to always show pi3statusBar so we can animate open/close by height
      return (
        <div className='pi3statusBar'></div>
      );
    }

    let lastMessage = this.state.messages[this.state.messages.length - 1];

    let statusBarClass = 'pi3statusBar open';
    if (lastMessage.status === 'success') {
      statusBarClass += ' success';
    }
    else if (lastMessage.status === 'warning') {
      statusBarClass += ' warning';
    }

    return (
      <div className={statusBarClass}>
        <p>{lastMessage.message}</p>
        <p onClick={this.dismissLastMessage} className='pi3statusBarButton'>Dismiss</p>
      </div>
    );
  }
}

module.exports = {
  Component: StatusBar,
  sendMessage: sendMessage,
};